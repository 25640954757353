import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Link from "gatsby-link";

const HowWorks = () => {
  function Sports() {
    return (
      <StaticImage
        src="../../images/sports.png"
        alt="good for sports - shroom energy drink"
        title="good for sports"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Brain() {
    return (
      <StaticImage
        src="../../images/brain.png"
        alt="improves concentration - shroom energy drink"
        title="improves concentration"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Guts() {
    return (
      <StaticImage
        src="../../images/guts.png"
        alt="good for Your guts - shroom energy drink"
        title="good for Your guts"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Head() {
    return (
      <StaticImage
        src="../../images/head.png"
        alt="helps You relax - shroom energy drink"
        title="helps You relax"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  return (
    <>
      <section id="how-works" style={{ marginTop: "5em" }}>
        <h2
          className="display-8"
          style={{ padding: "10px", textAlign: "center" }}
        >
          The choice of feeling good
        </h2>
        <div
          className="row"
          style={{ minWidth: "100%", padding: "2rem", margin: "0" }}
        >
          <div className="col-3" style={{ textAlign: "center" }}>
            {Sports()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Boosted energy
              <br /> and endurance
            </h3>
            <br />
            <p className="my-3" style={{ textAlign: "left" }}>
              NO caffeine crush <br />
              NO jitters
              <br />
              Better recovery
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Brain()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Increase <br />
              of productivity
            </h3>
            <br />
            <p className="my-3" style={{ textAlign: "left" }}>
              Improved focus and memory
              <br />
              <br />
              Nootropic drink to support Your brain
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Guts()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Good for
              <br /> Your guts
            </h3>
            <br />
            <p className="my-3" style={{ textAlign: "left" }}>
              Probiotic inuline inside <br />
              <br />
              Immune system support
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Head()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Feel
              <br /> great
            </h3>
            <br />
            <p className="my-3" style={{ textAlign: "left" }}>
              Stress reduction <br />
              <br />
              Overall well-being improvement
            </p>
          </div>
        </div>
        <p id="buttons" style={{ textAlign: "center" }}>
          Choose your <span className="spec-h">shroom</span>
          <br />
          <br />
          <Link
            to="/product/shroom-power-6-pack-2/"
            className="btn btn-outline-dark mush"
            style={{ margin: "10px" }}
          >
            <span className="spec-h">shroom power</span>&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
          <Link
            to="/product/shroom-relax-6-pack/"
            className="btn btn-outline-dark mush"
            style={{ margin: "10px" }}
          >
            <span className="spec-h">shroom relax</span>&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
        </p>
      </section>
    </>
  );
};

export default HowWorks;
